import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordName,
} from 'vue-router';

import { useUserStore } from '@/stores/userStore';

const checkParents = (to: RouteLocationNormalized, from: RouteLocationNormalized): boolean => {
  if (to.meta.entryPoint) return true;
  return !!(
    (to.meta.parents && (to.meta.parents as RouteRecordName[]).includes(from?.name || '')) ||
    (from.meta.parents && (from.meta.parents as RouteRecordName[]).includes(to?.name || ''))
  );
};

// const spffunnelCheckParentsV1 = async (
//   to: RouteLocationNormalized,
//   from: RouteLocationNormalized
// ): Promise<boolean> => {
//   if (checkParents(to, from)) return true;
//   else {
//     // hack to go back from shopify
//     const infoBuilder = JSON.parse(localStorage.getItem('spf-builder') || '{}');
//     if (infoBuilder?.checkout_redirect && to.path === '/allinone/summary' && from.path === '/') return true;
//     else router.push({ name: 'spf-step-1-V1', query: to.query });
//     return false;
//   }
// };
//
// const spffunnelCheckParentsV2 = async (
//   to: RouteLocationNormalized,
//   from: RouteLocationNormalized
// ): Promise<boolean> => {
//   if (checkParents(to, from)) return true;
//   else {
//     // hack to go back from shopify
//     const infoBuilder = JSON.parse(localStorage.getItem('spf-builder') || '{}');
//     if (infoBuilder?.checkout_redirect && to.path === '/all-in-one/summary' && from.path === '/') return true;
//     else router.push({ name: 'spf-step-1-V2', query: to.query });
//     return false;
//   }
// };

const funnelCheckParents = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
): Promise<boolean> => {
  if (checkParents(to, from)) return true;
  else {
    // hack to go back from shopify)
    const infoBuilder = JSON.parse(localStorage.getItem('builder') || '{}');
    if (infoBuilder?.checkout_redirect && to.path === '/step-3' && from.path === '/') return true;
    else router.push({ name: 'step-1', query: to.query });
    return false;
  }
};

const fbcCheckParents = (to: RouteLocationNormalized, from: RouteLocationNormalized): boolean => {
  if (checkParents(to, from)) return true;
  else {
    // hack to go back from shopify
    const infoBuilder = JSON.parse(localStorage.getItem('fbc-builder') || '{}');
    if (infoBuilder?.checkout_redirect && to.path === '/summary' && from.path === '/') return true;
    else router.push({ name: 'fbc-step-2', query: to.query });
    return false;
  }
};

export const authRouteGuard = async (to: RouteLocationNormalized, next: NavigationGuardNext) => {
  const userStore = useUserStore();
  await userStore.getUserDetails();
  const logged = !!userStore.attributes?.id;
  logged ? next() : (window.location.href = `${import.meta.env.VITE_SHOPIFY_URL}/account/login`);
};

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash && to.hash !== '#modal') {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/components/funnel/FunnelRoot.vue'),
      beforeEnter: async (to, from, next) => {
        const userStore = useUserStore();
        userStore.getUserDetails();
        next();
      },
      children: [
        {
          path: '',
          component: () => import('@/views/funnel/Step1View.vue'),
          name: 'step-1',
          meta: {
            parents: ['/', '', undefined, 'step-2', 'step-3', 'basket-summary'],
            entryPoint: true,
          },
          beforeEnter: [funnelCheckParents],
        },
        {
          path: 'step-2',
          component: () => import('@/views/funnel/Step2View.vue'),
          name: 'step-2',
          meta: { parents: ['step-1', 'step-3', 'basket-summary'] },
          beforeEnter: [funnelCheckParents],
        },
        {
          path: 'step-3',
          component: () => import('@/views/funnel/Step3View.vue'),
          name: 'step-3',
          meta: { parents: [undefined, 'step-2', 'basket-summary'] },
          beforeEnter: [funnelCheckParents],
        },
        {
          path: 'summary',
          component: () => import('@/views/funnel/BasketSummaryView.vue'),
          name: 'basket-summary',
          meta: { parents: ['step-1', 'step-2', 'step-3'], reload: true },
          beforeEnter: [funnelCheckParents],
        },
        {
          path: 'user',
          component: () => import('@/views/funnel/UserView.vue'),
          name: 'pinter-user',
          meta: { parents: ['step-3'], reload: true },
          beforeEnter: [funnelCheckParents],
        },
      ],
    },
    {
      path: '/subscribe',
      component: () => import('@/components/fbc-funnel/FbcRoot.vue'),
      beforeEnter: async (to, from, next) => {
        const userStore = useUserStore();
        userStore.getUserDetails();
        next();
      },
      children: [
        {
          path: '',
          component: () => import('@/views/fbc-funnel/FbcStep2View.vue'),
          name: 'fbc-step-2',
          meta: {
            parents: ['/', '', undefined, 'basket-summary'],
            entryPoint: true,
          },
          beforeEnter: [fbcCheckParents],
        },
        {
          path: '/subscribe/basket-summary',
          component: () => import('@/views/fbc-funnel/FbcBasketSummaryView.vue'),
          name: 'fbc-basket-summary',
          meta: {
            parents: ['fbc-step-2'],
          },
          beforeEnter: [fbcCheckParents],
        },
        {
          path: 'user',
          component: () => import('@/views/funnel/UserView.vue'),
          name: 'fbc-user',
          meta: { parents: ['fbc-step-2'], reload: true },
          beforeEnter: [fbcCheckParents],
        },
      ],
    },
    {
      path: '/account',
      component: () => import('@/components/account/AccountRoot.vue'),
      beforeEnter: async (to, from, next) => {
        await authRouteGuard(to, next);
      },
      children: [
        {
          path: '',
          component: () => import('@/components/account/AccountOverviewRoot.vue'),
          props: true,
          children: [
            {
              path: '',
              component: () => import('@/components/account/AccountOverview.vue'),
              name: 'account-overview',
            },
            {
              path: '/account/overview/edit-order/:id',
              component: () => import('@/components/account/AccountEditOrder.vue'),
              name: 'account-overview-edit',
              props: true,
            },
          ],
        },
        {
          path: '/account/details',
          component: () => import('@/components/account/AccountDetailsRoot.vue'),
          children: [
            {
              path: '',
              component: () => import('@/components/account/AccountDetails.vue'),
              name: 'account-details',
            },
            {
              path: '/account/details/adresses',
              component: () => import('@/components/account/addresses/AddressesRoot.vue'),
              children: [
                {
                  path: '',
                  component: () => import('@/components/account/addresses/Addresses.vue'),
                  name: 'account-addresses',
                },
                {
                  path: '/account/details/adresses/edit/:id',
                  component: () => import('@/components/account/addresses/AddressesEdit.vue'),
                  name: 'account-addresses-edit',
                  props: true,
                },
                {
                  path: '/account/details/adresses/new',
                  component: () => import('@/components/account/addresses/AddressesNew.vue'),
                  name: 'account-addresses-new',
                },
              ],
            },
            {
              path: '/account/details/payment',
              component: () => import('@/components/account/paymentMethod/PaymentMethod.vue'),
              name: 'account-payment-method',
            },
            {
              path: '/account/details/contact',
              component: () => import('@/components/account/contactDetails/ContactDetailsRoot.vue'),
              children: [
                {
                  path: '',
                  component: () => import('@/components/account/contactDetails/ContactDetails.vue'),
                  name: 'account-contact-details',
                },
                {
                  path: '/account/details/contact/phone',
                  component: () => import('@/components/account/contactDetails/ContactDetailsPhone.vue'),
                  name: 'account-contact-details-phone',
                },
              ],
            },
          ],
        },
        // {
        // 	path: '/account/referrals',
        // 	component: () => import('@/components/account/AccountReferrals.vue'),
        // 	name: 'account-referrals',
        // },
        {
          path: '/account/subscription',
          component: () => import('@/components/account/AccountSubscriptionRoot.vue'),
          children: [
            {
              path: '',
              component: () => import('@/components/account/AccountSubscription.vue'),
              name: 'account-subscription',
            },
            {
              path: '/account/subscription/edit-order/:id',
              component: () => import('@/components/account/AccountEditOrder.vue'),
              name: 'account-subscription-edit',
              props: true,
              beforeEnter: (to, from, next) => {
                next();
              },
            },
          ],
        },
        {
          path: '/account/history',
          component: () => import('@/components/account/AccountHistory.vue'),
          name: 'account-history',
        },
      ],
    },
    {
      path: '/subscribe/:pathMatch(.*)*',
      name: 'fbc-not-found',
      redirect: { name: 'fbc-step-2' },
    },
    {
      path: '/account/:pathMatch(.*)*',
      name: 'account-not-found',
      redirect: { name: 'account-overview' },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      redirect: { name: 'step-1' },
    },
    // {
    //   path: '/allinone',
    //   component: () => import('@/components/spread-payments-funnel/SPFFunnelRoot.vue'),
    //   beforeEnter: async (to, from, next) => {
    //     const userStore = useUserStore();
    //     userStore.getUserDetails();
    //     next();
    //   },
    //   props: { urlParam: 'V1' },
    //   children: [
    //     {
    //       path: '',
    //       component: () => import('@/views/spread-payments-funnel/Step1View.vue'),
    //       name: 'spf-step-1-V1',
    //       meta: {
    //         parents: ['/allinone', '', undefined, 'spf-step-2-V1', 'spf-basket-summary-V1'],
    //         entryPoint: true,
    //       },
    //       beforeEnter: [spffunnelCheckParentsV1],
    //     },
    //     {
    //       path: 'step-2',
    //       component: () => import('@/views/spread-payments-funnel/Step2View.vue'),
    //       name: 'spf-step-2-V1',
    //       meta: { parents: ['spf-step-1-V1', 'spf-basket-summary-V1'] },
    //       beforeEnter: [spffunnelCheckParentsV1],
    //     },
    //     {
    //       path: 'summary',
    //       component: () => import('@/views/spread-payments-funnel/BasketSummaryView.vue'),
    //       name: 'spf-basket-summary-V1',
    //       meta: { parents: ['spf-step-1-V1', 'spf-step-2-V1'], reload: true },
    //       beforeEnter: [spffunnelCheckParentsV1],
    //     },
    //   ],
    // },
    // {
    //   path: '/all-in-one',
    //   component: () => import('@/components/spread-payments-funnel/SPFFunnelRoot.vue'),
    //   beforeEnter: async (to, from, next) => {
    //     const userStore = useUserStore();
    //     userStore.getUserDetails();
    //     next();
    //   },
    //   props: { urlParam: 'V2' },
    //   children: [
    //     {
    //       path: '',
    //       component: () => import('@/views/spread-payments-funnel/Step1View.vue'),
    //       name: 'spf-step-1-V2',
    //       meta: {
    //         parents: ['/all-in-one', '', undefined, 'spf-step-2-V2', 'spf-basket-summary-V2'],
    //         entryPoint: true,
    //       },
    //       beforeEnter: [spffunnelCheckParentsV2],
    //     },
    //     {
    //       path: 'step-2',
    //       component: () => import('@/views/spread-payments-funnel/Step2View.vue'),
    //       name: 'spf-step-2-V2',
    //       meta: { parents: ['spf-step-1-V2', 'spf-basket-summary-V2'] },
    //       beforeEnter: [spffunnelCheckParentsV2],
    //     },
    //     {
    //       path: 'summary',
    //       component: () => import('@/views/spread-payments-funnel/BasketSummaryView.vue'),
    //       name: 'spf-basket-summary-V2',
    //       meta: { parents: ['spf-step-1-V2', 'spf-step-2-V2'], reload: true },
    //       beforeEnter: [spffunnelCheckParentsV2],
    //     },
    //   ],
    // },
  ],
});

router.beforeEach(function (to, from, next) {
  if (Object.keys(from.query).length > 0 && Object.keys(to.query).length === 0) {
    next({ name: to.name || '', query: from.query, params: to.params });
  } else next();
});

export default router;
